<template>
  <div class="aldeasInteligentes">
        <!-- CONTENIDO -->
    <div class="container">
      <!-- BREADCRUM + LOGOTIPO + DATOS DE USUARIO -->
      <div class="row top-buffer">
        <div class="col-md-12">
          <ol class="breadcrumb">
            <li><a href="https://www.gob.mx"><i class="icon icon-home"></i></a></li>
            <li><a href="https://www.gob.mx/sct">SCT</a></li>
            <li>Portal Cobertura Universal</li>
            <li class="active">Aldeas inteligentes, Bienestar sostenible-</li>
          </ol>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" id="logotipo">
          <img src="../assets/img/logo.png" alt="Secretaría de Comunicaciones y Transportes" />
        </div>
      </div>

      <!-- TÍTULO Y SUBTÍTULO -->
      <div class="row">
        <div class="col-md-8">
          <h1 style="margin-bottom:30px;">Aldeas inteligentes, Bienestar sostenible </h1>
          
          <hr class="red">
        </div>
        <div class="col-md-4">
          <img class="card-img-img" src="../assets/img/wsispp_w-min.png" alt="Reconocimiento">
        </div>

      </div>

      <!-- CONTENIDO -->
      <div class="row">
        <div class="col-md-12">
          <p class="text-justify">La SCT a través de este proyecto tiene el objetivo de conectar sitios públicos en localidades rurales para que éstas puedan aprovechar al máximo las posibilidades y los recursos de la conectividad a Internet para elevar su calidad de
            vida y nivel de bienestar.</p>
          <p>Para el diseño de este proyecto, la SCT tomó como base el concepto de “Smart Village” de la UIT y los ODS con el objetivo de impulsar el acceso digital con fines sustentables y sostenibles para mejorar la calidad de vida y el nivel de
            bienestar de los habitantes de las comunidades rurales en México.</p>
          <p>El <strong>modelo de Aldeas Inteligentes</strong>, Bienestar Sostenible se desarrolla a partir de 4 etapas específicas: <strong>Acceso, Uso, Apropiación y Bienestar</strong>.</p>
        </div>

        <!-- Seccion: Cabecera -->
        <div class="col-md-12">
          <img class="img-responsive" src="../assets/img/aldeas_inteligentes/cabecera.png" />
        </div>
            <!-- Seccion: Participaciones internacionales -->
       <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <h2 class="text-warning">Participaciones internacionales</h2>
            </div>
          </div>
          <div class="row" style="margin-top: 30px;">
            <div class="col-md-5">
              <img class="img-responsive" src="../assets/img/aldeas_inteligentes/wsis.png" />
            </div>
            <div class="col-md-7">
              <strong>Nominación en premios </strong>
              <ul>
                <li class="text-justify" style="margin-top: 20px;">Los premios de la Cumbre Mundial de la Sociedad de la Información y del Conocimiento (CMSI) (WSIS, por sus siglas en inglés), es organizado por la Unión Internacional de Telecomunicaciones (UIT), para identificar y reconocer modelos e iniciativas exitosas de todo el mundo, que pueden ser replicados para aprovechar las ventajas de las Tecnologías de la Información y Comunicación, para contribuir a la aceleración del desarrollo social y económico de la comunidad, y hacer frente a los nuevos desafíos que plantea la Sociedad de la Información en los planos nacional, regional e internacional.</li>
                <li class="text-justify" style="margin-top: 20px;">En la edición 2023, “Aldeas Inteligentes, Bienestar Sostenible”, fue uno de los 19 proyectos nominados en la categoría 1 “El rol de los gobiernos y todas las partes interesadas en la promoción de las TIC para el desarrollo”, y durante la Cumbre Mundial de la Sociedad de la Información 2023, celebrada del 13 al 17 del 2023 en Ginebra, Suiza; el proyecto recibió el premio “Champions”.</li>
                <li class="text-justify" style="margin-top: 20px;">Comunicado SICT: <a  href="https://www.gob.mx/sct/prensa/recibe-sict-premio-internacional-por-proyecto-de-inclusion-digital" >Sitio</a></li> 
              </ul>
            </div>
            <div class="col-md-5">
              <img class="img-responsive" src="../assets/img/aldeas_inteligentes/partner2.png" />
            </div>
           
            
              
            <div class="col-md-7">
              <br>
              <p></p>
              <p></p>
              
              <strong>Partner2Connect</strong>
              <ul>
                <li class="text-justify" style="margin-top: 20px;">Partner2Connect es una alianza multilateral promovida por la Unión Internacional de Telecomunicaciones (UIT) para fomentar la conectividad universal y la transformación digital en todo el mundo, centrándose en las comunidades más difíciles de conectar.</li>
                <li class="text-justify" style="margin-top: 20px;">Durante la Reunión Anual de Partner2Connect, realizada el 8 de diciembre de 2022 en Ginebra; se reportaron los avances en los compromisos de política pública registrados para lograr la conectividad universal, de los cuales, Aldeas Inteligentes, Bienestar Sostenible, es uno de ellos.</li>
                </ul>
          </div>
          <div>
            <h2  >Testimonio Aldeas inteligentes, Bienestar sostenible.</h2 >
                
          </div>
          <div>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/lQqqNJrV0yQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              
          </div>
          
        </div>
        <!-- Seccion: Concepto -->
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <h2 class="text-warning">Concepto</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12" style="margin: 30px;">
              <p class="text-justify">La Secretaría de Infraestructura, Comunicaciones y Transportes desarrolló el modelo “Aldeas Inteligentes, bienestar sostenible”, a través del cual las <strong>comunidades ubicadas en áreas rurales</strong> pueden <strong>aprovechar</strong> al máximo las posibilidades y los recursos de la <strong>conectividad digital</strong> para elevar su calidad de vida y nivel de bienestar, contribuyendo para el logro de los Objetivos de Desarrollo Sostenible de las Naciones Unidas.</p>
            </div>
          </div>
          <div class="alert alert-warning">Criterios de selección de los sitios</div>
          <div class="row" style="margin-top: 20px;">
            <div class="col-md-6">
              <p class="text-warning" style="margin-bottom: 50px;"><strong>Localidades, preferentemente, de no más de 2500 habitantes, sin conectividad a internet:</strong></p>
              <div class="row" style="margin-top: 10px;">
                <div class="col-xs-2 col-sm-2 col-md-2">
                  <img class="img-responsive" src="../assets/img/aldeas_inteligentes/02-01.png" />
                </div>
                <div class="col-xs-10 col-sm-10 col-md-10">
                  <p style="margin-left: 15px;">Donde la actividad agrícola, ganadera y pesquera permita las posibilidades de exportación de algún producto, y sea sostenible y sustentable.</p>
                </div>
              </div>
              <div class="row" style="margin-top: 10px;">
                <div class="col-xs-2 col-sm-2 col-md-2">
                  <img class="img-responsive" src="../assets/img/aldeas_inteligentes/02-02.png" />
                </div>
                <div class="col-xs-10 col-sm-10 col-md-10">
                  <p style="margin-left: 15px;">Existan posibilidades de turismo sostenible que brinden empleo y desarrollo económico a través del ecoturismo.</p>
                </div>
              </div>
              <div class="row" style="margin-top: 10px;">
                <div class="col-xs-2 col-sm-2 col-md-2">
                  <img class="img-responsive" src="../assets/img/aldeas_inteligentes/02-03.png" />
                </div>
                <div class="col-xs-10 col-sm-10 col-md-10">
                  <p style="margin-left: 15px;">Ubicadas cerca de áreas naturales protegidas con el fin de ayudar a su conservación.</p>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <p class="text-warning" style="margin-bottom: 50px;"><strong>En términos de población, localidades:</strong></p>
              <div class="row" style="margin-top: 10px;">
                <div class="col-xs-2 col-sm-2 col-md-2">
                  <img class="img-responsive" src="../assets/img/aldeas_inteligentes/02-04.png" />
                </div>
                <div class="col-xs-10 col-sm-10 col-md-10">
                  <p style="margin-left: 15px;">Donde alrededor del 50% de la población productiva sea femenina o indígena, que la matrícula escolar sea mayor al 20%.</p>
                </div>
              </div>
              <div class="row" style="margin-top: 10px;">
                <div class="col-xs-2 col-sm-2 col-md-2">
                  <img class="img-responsive" src="../assets/img/aldeas_inteligentes/02-05.png" />
                </div>
                <div class="col-xs-10 col-sm-10 col-md-10">
                  <p style="margin-left: 15px;">Con un alto bono demográfico, es decir, cuando el volumen de la población en edad de trabajar, ubicada entre los 14 y 59 años, supere a la población dependiente.</p>
                </div>
              </div>
              <div class="row" style="margin-top: 10px;">
                <div class="col-xs-2 col-sm-2 col-md-2">
                  <img class="img-responsive" src="../assets/img/aldeas_inteligentes/02-06.png" />
                </div>
                <div class="col-xs-10 col-sm-10 col-md-10">
                  <p style="margin-left: 15px;">Donde los habitantes se comprometan a involucrarse en el proyecto, para que el desarrollo y apropiación de las “” sea exitoso.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Sección: Etapas del Modelo -->
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <h2 class="text-warning">Etapas del Modelo</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12" style="margin: 30px;">
              <img class="img-responsive" src="../assets/img/aldeas_inteligentes/cobertura.png" />
            </div>
          </div>
        </div>

        <!-- Seccion: Modelo de conectividad -->
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <h2 class="text-warning">Modelo de conectividad</h2>
            </div>
          </div>
          <div class="row" style="margin-top: 30px;">
            <div class="col-md-5">
              <img class="img-responsive" src="../assets/img/aldeas_inteligentes/diagrama_satelite.png" />
            </div>
            <div class="col-md-7" >
              La provisión de conectividad es mediante <strong>Internet satelital provisto</strong> por <strong>Viasat</strong> como <strong>contraprestación de la Capacidad Satelital Reservada del Estado</strong> (75 sitios):
              <ul>
                <li style="margin-top: 20px;">Velocidad de bajada de hasta <strong>12 Mbps</strong> y una velocidad de subida de hasta <strong>2 Mbps</strong> durante el 95% del tiempo.</li>
                <li style="margin-top: 20px;">Se cuenta con <strong>Política de Acceso Justo</strong> con <strong>55 GB de disponibilidad mensual</strong> de datos por cada sitio (cuando un sitio alcance el umbral de datos mensuales, el servicio se reduce a una velocidad de bajada y subida de 1.0 Mbps), así mismo se restringen la visualización de videos y las cargas o descargas de archivos digitales.</li>
              </ul>
            </div>
          </div>
          <div class="alert alert-warning text-center" style="margin-top: 30px;">Se busca el <strong>empoderamiento de las Aldeas Inteligentes</strong> para alcanzar un modelo de <strong>bienestar sostenible</strong> por la misma localidad</div>
        </div>

        <!-- Aldeas Inteligentes instaladas -->
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <h2 class="text-warning">Aldeas Inteligentes instaladas</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <table class="table text-center" border="1">
                <tr v-for="(item, index) in items" :key="item.id">
                  <td>{{ index+1 }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.aiis }}</td>
                </tr>
                <tr>
                  <td colspan="2">Total</td>
                  <td>{{ total_aiis }}</td>
                </tr>
              </table>
            </div>
           <div class="col-md-8">
              <svg-map 
                :map="Mexico"
                @mouseover="pointLocation"
                @mouseout="unpointLocation"
                @mousemove="moveOnLocation"
              />
              <div class="alert alert-warning" :style="tooltipStyle">{{ pointedLocation }}</div>
            </div> 
          </div>
        </div>

        <!-- Aldeas Inteligentes instaladas (lista) -->
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12">
              <h2 class="text-warning">Aldeas Inteligentes instaladas</h2>
            </div>
          </div>
          <div class="row">
            <div class="card col-md-4" v-for="aii in aiis" :key="aii.id" style="border: 0; margin-top: 35px;">
              <div class="card-body">
                <h4 class="card-title">{{ aii.name }}, {{ aii.location }}</h4>
              </div>
              <img class="card-img-bottom img-responsive" :src="`${loadImg(aii.image)}`" alt="">
            </div>
          </div>
          <div class="row" v-for="aii in aiis2" :key="aii.id" style="margin-top: 20px;">
            <h3> <span class="glyphicon glyphicon-map-marker"></span> {{ aii.name }}</h3>
            <div class="card col-md-4" v-for="image in aii.images" :key="image" style="border: 0; margin-top: 35px;">
              <img class="card-img-bottom img-responsive" :src="`${loadImg2(image)}`" alt="">
            </div>
          </div>
        </div>
      </div>
   
         </div> 
       <div class="row">
        <div class="col-md-12 text-right">
          <hr />
          <a class="btn btn-default" type="button" href="/aldeas-inteligentes/mapa-interactivo" >Mapa interactivo</a>
          <a class="btn btn-default" type="button" href="files/20211111 Aldeas Inteligentes instaladas.csv" target="__blank">Descargar datos abiertos</a>
          <a class="btn btn-default" type="button" href="/">Regresar</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../router"

import { SvgMap } from "vue-svg-map";
import Mexico from "@svg-maps/mexico";

const images = require.context('@/assets/img/aldeas_inteligentes/ai1', false, /\.png$|\.jpg$/)
const images2 = require.context('@/assets/img/aldeas_inteligentes/ai2', false, /\.png$|\.jpg$/)

export default {
  name: 'aldeasInteligentes',
  methods: {
    back(){
      router.replace('/')
    },
  },
  components: {
    SvgMap
  },
  data() {
    return {
      Mexico,
      pointedLocation: null,
      tooltipStyle: { display: 'none' },
      total_aiis: 0,
      // ESTRUCTRA PARA LA TABLA
      //items: [{name:"Aguascalientes",id:"agu",aiis:2},{name:"Baja California",id:"bcn",aiis:2},{name:"Baja California Sur",id:"bcs",aiis:2},{name:"Campeche",id:"cam",aiis:2},{name:"Chiapas",id:"chp",aiis:2},{name:"Chihuahua",id:"chh",aiis:2},{name:"Coahuila",id:"coa",aiis:2},{name:"Colima",id:"col",aiis:2},{name:"Durango",id:"dur",aiis:2},{name:"Guanajuato",id:"gua",aiis:2},{name:"Guerrero",id:"gro",aiis:2},{name:"Hidalgo",id:"hid",aiis:2},{name:"Jalisco",id:"jal",aiis:2},{name:"Mexico City",id:"cmx",aiis:2},{name:"México",id:"mex",aiis:2},{name:"Michoacán",id:"mic",aiis:2},{name:"Morelos",id:"mor",aiis:2},{name:"Nayarit",id:"nay",aiis:2},{name:"Nuevo León",id:"nle",aiis:2},{name:"Oaxaca",id:"oax",aiis:2},{name:"Puebla",id:"pue",aiis:2},{name:"Querétaro",id:"que",aiis:2},{name:"Quintana Roo",id:"roo",aiis:2},{name:"San Luis Potosí",id:"slp",aiis:2},{name:"Sinaloa",id:"sin",aiis:2},{name:"Sonora",id:"son",aiis:2},{name:"Tabasco",id:"tab",aiis:2},{name:"Tamaulipas",id:"tam",aiis:2},{name:"Tlaxcala",id:"tla",aiis:2},{name:"Veracruz",id:"ver",aiis:2},{name:"Yucatán",id:"yuc",aiis:2},{name:"Zacatecas",id:"zac",aiis:2}],
      items: require("../assets/json/items_aiis.json"),
      aiis: require("../assets/json/aiis.json"),
      aiis2: require("../assets/json/aiis2.json")
    };
  },
  created() {
    this.initData();
  },
  mounted() {
    this.getLocationClass();
  },
  methods: {
    async initData() {
      this.total_aiis = this.sumAiis();
    },
    pointLocation(event) {
      for (let index = 0; index < this.items.length; index++) {
        const element = this.items[index];
        if (event.target.id == element.id) {
          this.pointedLocation = element.aiis
        }
      }
    },
    getMaxAIIS() {
      let max_aiis = 0
      for (let index = 0; index < this.items.length; index++) {
        const element = this.items[index];
        if (element.aiis > max_aiis) {
          max_aiis = element.aiis
        }
      }
      return max_aiis
    },
    getOpacity(aiis) {
      let max_aiis = this.getMaxAIIS()
      let opacity = (aiis+10) * 100 / (max_aiis+10)
      return opacity+"%"
    },
    unpointLocation(event) {
			this.pointedLocation = null
      this.tooltipStyle = { display: 'none' }
		},
		moveOnLocation(event) {
      if (!(this.pointedLocation == null)) {
        this.tooltipStyle = {
          display: 'block',
          top: `${event.clientY + 10}px`,
          left: `${event.clientX - 20}px`,
          position: 'fixed',
        }
      }
		},
    getLocationClass() {
      $(".svg-map__location").css("fill", "lightgray").css("opacity", "30%");
      for (let index = 0; index < this.items.length; index++) {
        const element = this.items[index];
        var $element_js = $("#"+element.id);
        let opacity = this.getOpacity(element.aiis)
        $($element_js).css("fill", "red").css("opacity", opacity).attr("aria-checked", "true");
      }
		},
    sumAiis() {
      let total = 0
      for (let index = 0; index < this.items.length; index++) {
        const element = this.items[index];
        total += element.aiis
      }
      return total
    },
    loadImg(imgPath) {
      return images('./' + imgPath)
    },
    loadImg2(imgPath) {
      return images2('./' + imgPath)
    }
  }
}
</script>

<style src="vue-svg-map/dist/index.css"></style>
